import React, { Suspense, lazy, useState } from "react";
import "./app.css";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Careers from "./Careers/Index";
import ContactUs from "./ContactUs/Index";
import NewsEventsPage from "./NewsEvents/Index";
import CustomDesignedTool from "./Product/CustomDesignedTool/Index";
import ProductPage from "./Product/Index";
import ParentProductPage from "./Product/ParentProductPage";
import ReductionSleeves from "./Product/ReductionSleeves/Index";
import DrivenToolHolders from "./Product/ToolHolders/DrivenToolHolders/Index";
import VDIToolHolders from "./Product/ToolHolders/VDIToolHolders/Index";
import CustomDesignToolsDisc from "./Product/CustomDesignedTool/ToolDisc/Index";
import Catalog from "./Catalog/Catalog";
import CustomDesignToolsHolder from "./Product/CustomDesignedTool/ToolHolder/Index";
import ResourcesPage from "./Resources/Index";
import Blog from "./Blog/Index";
import { Helmet } from "react-helmet";
import Seo from "./Seo/index";
const HomePage = lazy(() => import("./Home/Index"));
const CompanyPage = lazy(() => import("./Company/Index"));
const VDIToolDiscs = lazy(() =>
  import("./Product/ToolDiscs/VDIToolDiscs/Index")
);
const SlottedToolDiscs = lazy(() =>
  import("./Product/ToolDiscs/SlottedToolDiscs/Index")
);
const InfrastructurePage = lazy(() => import("./Infrastructure/Index"));
// const Company = lazy(() => import("./Components/Comapny"));

const App = () => {
  const [navigationId, setNavigationId] = useState(1);
  const [ProductData, setProductData] = useState({});
  const [ParentProduct, setParentProduct] = useState([]);
  // const [navIdHome, setNavIdHome] = useState(0);

  const x = window.location.href;
  console.log(`www.${x} `, "bingo");

  let date = new Date();

  var year = date.toLocaleString("default", { year: "numeric" });
  var month = date.toLocaleString("default", { month: "2-digit" });
  var day = date.toLocaleString("default", { day: "2-digit" });
  // var time = date.toLocaleString("default", { time: "2-digit" });

  var todaysDate = day + "-" + month + "-" + year;

  localStorage.setItem("todays", todaysDate);

  let priviousDate = localStorage.getItem("todays");

  if (priviousDate !== todaysDate) {
    localStorage.clear();
  } else {
    localStorage.setItem("todays", todaysDate);
  }

  return (
    <Router>
      <Seo />
      {/* {window.innerWidth > 1000 && (
        <div
          style={{
            position: "absolute",
            zIndex: "1",
            bottom: "0",
            left: "50%",
          }}
        >
          <h6>
            <strong>Designed</strong> and <strong>Developed</strong> by
            <strong>
              <a
                style={{ color: "red", textDecoration: "none" }}
                href="https://digikit.in/"
              >
                DIGIKIT
              </a>
            </strong>
          </h6>
        </div>
      )} */}

      {/* {window.innerWidth > 1000 && (
        <div
          style={{ position: "absolute", zIndex: "1", bottom: "0", left: "0%" }}
        >
          <ul className="social_media_wrapper">
            <li>
              <a href="https://www.facebook.com/profile.php?id=61557009941683">
                <FaFacebook />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/sphoorti_machine_tools">
                <FaInstagram />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/sphoorti_tools">
                <FaTwitter />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/sphoorti-machine-tools">
                <FaLinkedin />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/@SphoortiMachineTools">
                <FaYoutube />
              </a>
            </li>
          </ul>
        </div>
      )} */}
      {/* let text = "https://sphoorti.com/tool-holder/vdi-tool-holder";
        let result = text.slice(21);
        console.log(result.toUpperCase().replace('/','|')) */}
      {/* <title>{window.location.href}</title> */}
      {/* <title>Sphoorti Machine Tools Pvt Ltd</title> */}
      {/* <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet> */}
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <HomePage
                setParentProduct={setParentProduct}
                setNavigationId={setNavigationId}
              />
            )}
          />
          <Route
            exact
            path="/company"
            render={() => (
              <CompanyPage
                navigationId={navigationId}
                setParentProduct={setParentProduct}
                setNavigationId={setNavigationId}
              />
            )}
          />
          <Route
            exact
            path="/download-pdfs"
            render={() => (
              <Catalog
              // navigationId={navigationId}
              // setParentProduct={setParentProduct}
              // setNavigationId={setNavigationId}
              />
            )}
          />
          <Route
            exact
            path="/product-toolDiscs-vdiToolDiscs"
            render={() => (
              <VDIToolDiscs
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            exact
            path="/product-toolDiscs-slottedToolDiscs"
            render={() => (
              <SlottedToolDiscs
                navigationId={navigationId}
                setNavigationId={setNavigationId}
              />
            )}
          />
          <Route
            exact
            path="/product-toolHolders-vdiToolHolders"
            render={() => (
              <VDIToolHolders
                navigationId={navigationId}
                setNavigationId={setNavigationId}
              />
            )}
          />
          <Route
            exact
            path="/product-toolHolders-drivenToolHolders"
            render={() => (
              <DrivenToolHolders
                navigationId={navigationId}
                setNavigationId={setNavigationId}
              />
            )}
          />
          <Route
            exact
            path="/product-reductionSleeves"
            render={() => (
              <ReductionSleeves
                navigationId={navigationId}
                setNavigationId={setNavigationId}
              />
            )}
          />
          <Route
            exact
            path="/product-customDesignedTool"
            render={() => (
              <CustomDesignedTool
                navigationId={navigationId}
                setNavigationId={setNavigationId}
              />
            )}
          />
          <Route
            exact
            path="/infrastructure"
            render={() => (
              <InfrastructurePage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                setParentProduct={setParentProduct}
              />
            )}
          />
          <Route
            exact
            path="/product-data"
            render={() => (
              <ProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                setParentProduct={setParentProduct}
                ProductData={ProductData}
              />
            )}
          />
          <Route
            exact
            path="/product-data/:step1"
            render={() => (
              <ProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                setParentProduct={setParentProduct}
                ProductData={ProductData}
              />
            )}
          />
          <Route
            exact
            path="/product-data/:step1/:step2"
            render={() => (
              <ProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                setParentProduct={setParentProduct}
                ProductData={ProductData}
              />
            )}
          />
          <Route
            exact
            path="/product-data/:step1/:step2/:step3"
            render={() => (
              <ProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                setParentProduct={setParentProduct}
                ProductData={ProductData}
              />
            )}
          />
          <Route
            exact
            path="/product-data/:step1/:step2/:step3/:step4"
            render={() => (
              <ProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                setParentProduct={setParentProduct}
                ProductData={ProductData}
              />
            )}
          />
          <Route
            exact
            path="/product-data/:step1/:step2/:step3/:step4/:step5"
            render={() => (
              <ProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                setParentProduct={setParentProduct}
                ProductData={ProductData}
              />
            )}
          />
          <Route
            exact
            path="/product-data/:step1/:step2/:step3/:step4/:step5/:step6"
            render={() => (
              <ProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                setParentProduct={setParentProduct}
                ProductData={ProductData}
              />
            )}
          />
          <Route
            exact
            path="/news-events"
            render={() => (
              <NewsEventsPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                setParentProduct={setParentProduct}
              />
            )}
          />
          <Route
            exact
            path="/resources"
            render={() => (
              <ResourcesPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                setParentProduct={setParentProduct}
              />
            )}
          />
          <Route
            exact
            path="/blogs"
            render={() => (
              <ResourcesPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                setParentProduct={setParentProduct}
              />
            )}
          />
          <Route
            exact
            path="/blog/:title/:Blogid"
            render={() => (
              <Blog
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                setParentProduct={setParentProduct}
              />
            )}
          />
          <Route
            exact
            path="/careers"
            render={() => (
              <Careers
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                setParentProduct={setParentProduct}
              />
            )}
          />
          <Route
            exact
            path="/contact-us"
            render={() => (
              <ContactUs
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                setParentProduct={setParentProduct}
              />
            )}
          />
          {/* <Route
            path="/product"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/product/:step1"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
           <Route
            path="/product/:step1/:step2"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/product/:step1/:step2/:step3"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/product/:step1/:step2/:step3/:step4"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/product/:step1/:step2/:step3/:step4/:step5"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/product/:step1/:step2/:step3/:step4/:step5/:step6"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          /> */}
          <Route
            path="/tool-disc"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/tool-disc/:step1"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/tool-disc/:step1/:step2"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/tool-disc/:step1/:step2/:step3"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/tool-disc/:step1/:step2/:step3/:step4"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/tool-disc/:step1/:step2/:step3/:step4/:step5"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/tool-disc/:step1/:step2/:step3/:step4/:step5/:step6"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/tool-holder"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/tool-holder/:step1"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/tool-holder/:step1/:step2"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/tool-holder/:step1/:step2/:step3"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/tool-holder/:step1/:step2/:step3/:step4"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/tool-holder/:step1/:step2/:step3/:step4/:step5"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/tool-holder/:step1/:step2/:step3/:step4/:step5/:step6"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/accessories"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/accessories/:step1"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/accessories/:step1/:step2"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/accessories/:step1/:step2/:step3"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/accessories/:step1/:step2/:step3/:step4"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/accessories/:step1/:step2/:step3/:step4/:step5"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            path="/accessories/:step1/:step2/:step3/:step4/:step5/:step6"
            render={() => (
              <ParentProductPage
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            exact
            path="/customDesignedTool/tool-holder"
            render={() => (
              <CustomDesignToolsHolder
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
          <Route
            exact
            path="/product-customDesignedTool/tool-disc"
            render={() => (
              <CustomDesignToolsDisc
                navigationId={navigationId}
                setNavigationId={setNavigationId}
                ParentProduct={ParentProduct}
                setParentProduct={setParentProduct}
                setProductData={setProductData}
              />
            )}
          />
        </Switch>
      </Suspense>
      <div className="social_media_main_wrapper">
        <div className="social_media_icons_text">
          <p>Follow Us On :</p>
          <ul className="social_media_wrapper social_media_wrapper_mobile">
            <li className="footer_social_fb">
              <a href="https://www.facebook.com/SphoortiMachineToolsPvtLtd/">
                {/* https://www.facebook.com/profile.php?id=61557009941683 */}

                <FaFacebook />
              </a>
            </li>
            <li className="footer_social_ig">
              <a href="https://www.instagram.com/sphoorti_machine_tools">
                <FaInstagram />
              </a>
            </li>
            <li className="footer_social_twitter">
              <a href="https://twitter.com/sphoorti_tools">
                <FaTwitter />
              </a>
            </li>
            <li className="footer_social_ldi">
              <a href="https://www.linkedin.com/company/27778585/admin/page-posts/published/">
                {/* https://www.linkedin.com/company/sphoortimachinetools */}

                <FaLinkedin />
              </a>
            </li>
            <li className="footer_social_yt">
              <a href="https://www.youtube.com/@SphoortiMachineTools">
                <FaYoutube />
              </a>
            </li>
          </ul>
        </div>
        {/* <div className="developed_text_new">
          <h6>
            Designed and Developed by
            <a href="https://digikit.in/">DIGIKIT</a>
          </h6>
        </div> */}
      </div>
      {/* <div className="developed_text">
        <h6>
          Designed and Developed
          <a
            // style={{ color: "red", textDecoration: "none" }}
            href="https://digikit.in/"
          >
            DIGIKIT
          </a>
        </h6>
      </div> */}
    </Router>
  );
};

export default App;
